// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    applicationUrl: "https://nordicite.recursyve.dev",
    applicationOauthPath: "/oauth/login",
    apiUrl: "https://api.nordicite.recursyve.dev",
    sentryDsn: "https://8732fdd788174a8bb8c49ce592659729@o341312.ingest.sentry.io/6252967",
    sentryEnvironment: "staging",
    sentryReportErrors: true,
    sentryRelease: null,
    auth0: {
        domain: "https://nordicite-dev.us.auth0.com",
        clientId: "0lLX6YSUPt6HJqOc0nk2PO2GB8d9BI8E",
        clientSecret: "H6aR5kT-7jwz4myAD12hffLC4SlAFknVcIX704MDuzBobLRIq_c7QpJT8UsMgDg-",
        audience: "https://api.nordicite.recursyve.dev",
        scopes: ["openid", "profile", "email", "offline_access"],
        urlWhiteList: ["https://api.nordicite.recursyve.dev"]
    },
    firebase: {
        apiKey: "AIzaSyAyJYkH0kmPRkfhLGYOfnzG1XnIthTpY1k",
        authDomain: "nordicite-recursyve.firebaseapp.com",
        projectId: "nordicite-recursyve",
        storageBucket: "nordicite-recursyve.appspot.com",
        messagingSenderId: "750820603361",
        appId: "1:750820603361:web:5708b8c8035b6e1851cea0",
        measurementId: "G-1GKVMV1HG6"
    },
    syncfusionLicenseKey: "ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9TdkZiXHtYdH1QQWBd",
    flagsmithEnvironmentKey: "PhZZR7nr5HSHdUxBgiJgqQ",
    googleMapApiKey: "AIzaSyDhMb7fYurhqj_zA4hjDNR_twr3WNUUF4s",
    googleMapStyling: "43281859ebf89833"
};
