import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {
    NiceAsyncTypeaheadComponent,
    NiceMediaWatcherService,
    NiceVerticalNavigationAppearance,
    NiceVerticalNavigationComponent
} from "@recursyve/nice-ui-kit.v2";
import { GeneratedFormGroup, NgxFormGeneratorProvider } from "@recursyve/ngx-form-generator";
import { NavbarOrganizationSearchForm } from "./navbar-organization-search.form";
import { AppQuery } from "../../../../store/app.query";
import { AppService } from "../../../../store/app.service";
import { Router } from "@angular/router";
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    forkJoin,
    Observable,
    skip,
    Subject,
    Subscription,
    switchMap,
    takeUntil
} from "rxjs";
import { filter, map } from "rxjs/operators";
import { RolesEnum } from "../../../../api/nordicite/accounts/enums/roles.enum";
import { Auth0Users } from "../../../../api/nordicite/auth0/users/models/users.model";
import { NiceAuthenticationService } from "@recursyve/nice-auth0-kit";

@Component({
    selector: "nordicite-navbar",
    templateUrl: "./navbar.template.html",
    styleUrls: ["./navbar.style.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: NgxFormGeneratorProvider.forFeature([NavbarOrganizationSearchForm])
})
export class NavbarComponent implements OnInit, OnDestroy, OnChanges {
    private NAVIGATION_LOCAL_STORAGE_KEY = "navigation_appearance";

    @ViewChild(NiceVerticalNavigationComponent)
    public niceVerticalNavigation: NiceVerticalNavigationComponent;

    @Input()
    public opened: boolean;

    public currentOrganization$ = this.appQuery.selectCurrentOrganization();
    public currentAccount$ = this.appQuery.selectCurrentAccount();
    public userHasMultipleOrganizations$ = this.appQuery.selectUserHasMultipleOrganizations();
    public currentUser$: Subject<Auth0Users> = this.authService.currentUser$;

    public hovered$ = new BehaviorSubject<boolean>(false);
    public isScreenSmall$ = this.mediaWatcher.asObservable().pipe(map(() => this.mediaWatcher.isActive("lt-lg")));

    private _appearance$ = new BehaviorSubject<NiceVerticalNavigationAppearance>("default");
    public appearance$: Observable<NiceVerticalNavigationAppearance> = combineLatest([
        this._appearance$,
        this.isScreenSmall$
    ]).pipe(map(([appearance, isScreenSmall]) => (isScreenSmall ? "dense" : appearance)));

    public navBarIsCollapsed$ = combineLatest([this.appearance$, this.hovered$]).pipe(
        map(([appearance, hovered]) => appearance === "dense" && !hovered)
    );

    public imageRouterLink$ = combineLatest([
        this.appQuery.selectCurrentOrganization(),
        this.appQuery.selectCurrentAccount()
    ]).pipe(
        map(([currentOrganization, currentAccount]) => {
            const slug = currentAccount?.organization?.slug ?? currentOrganization?.slug;
            if (!slug) {
                return null;
            }

            return ["/", "dashboard", "organization", slug];
        })
    );

    protected readonly RolesEnum = RolesEnum;

    private unsubscribeAll$ = new Subject<void>();

    private isOpenedSubscription?: Subscription;

    @ViewChild("organizationTypeahead")
    public set organizationTypeahead(typeahead: NiceAsyncTypeaheadComponent | undefined) {
        if (!typeahead) {
            return;
        }
        if (this.isOpenedSubscription) {
            this.isOpenedSubscription.unsubscribe();
        }
        this.isOpenedSubscription = typeahead.isOpened$.subscribe(
            isOpened => (this.niceVerticalNavigation.forceHovered = isOpened)
        );
    }

    constructor(
        private mediaWatcher: NiceMediaWatcherService,
        public formGroup: GeneratedFormGroup<NavbarOrganizationSearchForm>,
        public appQuery: AppQuery,
        public appService: AppService,
        private authService: NiceAuthenticationService,
        public router: Router
    ) {}

    public async ngOnInit(): Promise<void> {
        combineLatest([this.currentAccount$, this.userHasMultipleOrganizations$])
            .pipe(takeUntil(this.unsubscribeAll$))
            .subscribe(([currentAccount, userHasMultipleOrganizations]) => {
                if (!currentAccount || !userHasMultipleOrganizations) {
                    return;
                }

                this.currentOrganization$
                    .pipe(
                        takeUntil(this.unsubscribeAll$),
                        map(organization => organization?.id),
                        distinctUntilChanged(),
                        filter(organizationId => !!organizationId)
                    )
                    .subscribe(organizationId => this.formGroup.patchValue({ organizationId }));
            });

        this.formGroup
            ?.get("organizationId")
            ?.valueChanges.pipe(
                takeUntil(this.unsubscribeAll$),
                distinctUntilChanged(),
                skip(1),
                filter(organizationId => !!organizationId),
                switchMap(organizationId =>
                    forkJoin([
                        this.appService.updateCurrentOrganizationById(organizationId),
                        this.appService.loadUserHasMultipleOrganizations()
                    ])
                )
            )
            .subscribe(
                async ([organization, _]) =>
                    await this.router.navigate(["dashboard", "organization", organization.slug])
            );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ("opened" in changes) {
            if (!this.opened) {
                // NiceVerticalNavigation always take the space in dense mode
                // https://github.com/Recursyve/nice-ui-kit/issues/142
                this._appearance$.next("default");
                return;
            }
            const storedAppearance = localStorage.getItem(this.NAVIGATION_LOCAL_STORAGE_KEY);
            if (storedAppearance === "default" || storedAppearance === "dense") {
                this._appearance$.next(storedAppearance);
            }
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll$.next();
        this.unsubscribeAll$.complete();
    }

    public clickNavbarMenu(): void {
        const newAppearance = this._appearance$.value === "default" ? "dense" : "default";
        this._appearance$.next(newAppearance);
        localStorage.setItem(this.NAVIGATION_LOCAL_STORAGE_KEY, newAppearance);
    }

    public async logout(): Promise<void> {
        this.appService.logout().subscribe();
        await this.router.navigate(["/sign-in"]);
    }
}
