import { ChangeDetectorRef, Component, ElementRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { _HintComponentBase } from "@recursyve/nice-ui-kit.v2";
import { AppQuery } from "../../../../../store/app.query";
import { map } from "rxjs/operators";
import { RolesEnum } from "../../../../../api/nordicite/accounts/enums/roles.enum";

@Component({
    selector: "nordicite-organization-module-navigation-item-hint",
    templateUrl: "./organization-module-navigation-item-hint.template.html",
    encapsulation: ViewEncapsulation.None
})
export class OrganizationModuleNavigationItemHintComponent extends _HintComponentBase {
    @ViewChild("hint", { static: true })
    public _hint: ElementRef<HTMLElement>;

    public role$ = this.appQuery.selectCurrentAccount().pipe(map(account => account?.role?.role));

    protected readonly RolesEnum = RolesEnum;

    constructor(changeDetectorRef: ChangeDetectorRef, private appQuery: AppQuery) {
        super(changeDetectorRef);
    }
}
