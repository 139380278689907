import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { OrganizationModuleNavigationItemHintComponent } from "./organization-module-navigation-item-hint.component";
import { ResolveDirective } from "@recursyve/nice-ui-kit.v2";
import { AsyncPipe, NgIf } from "@angular/common";

@NgModule({
    declarations: [OrganizationModuleNavigationItemHintComponent],
    imports: [TranslateModule, ResolveDirective, AsyncPipe, NgIf],
    exports: [OrganizationModuleNavigationItemHintComponent]
})
export class OrganizationModuleNavigationItemHintModule {}
