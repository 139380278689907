<div #hint class="bg-white shadow rounded-md py-2 px-4">
    <span *resolve="role$ | async as role" class="whitespace-pre-line">
        <span *ngIf="role === RolesEnum.SuperAdmin">
            {{ "features.dashboard.organizations.modules.admin_disable" | translate }}
        </span>
        <span *ngIf="role === RolesEnum.OrganizationManager">
            {{ "features.dashboard.organizations.modules.manager_disable" | translate }}
        </span>
    </span>
</div>
