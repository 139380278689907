import { Injectable } from "@angular/core";
import { NavigationHintResolver } from "@recursyve/nice-ui-kit.v2";
import { Observable, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class OrganizationModuleNavigationItemHintResolver extends NavigationHintResolver {
    constructor() {
        super();
    }

    public shouldShowHint(): Observable<boolean> {
        // Should only be added on disabled modules
        return of(true);
    }
}
