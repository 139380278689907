import { Injectable } from "@angular/core";
import { FormulasApi } from "./nordicite/formulas/formulas.api";
import { InfoApi } from "./nordicite/info/info.api";
import { AccountApi } from "./nordicite/accounts/account.api";
import { OrganizationApi } from "./nordicite/organizations/organization.api";
import { CountriesApi } from "./nordicite/coords/countries.api";
import { CacheApi } from "./nordicite/cache/cache.api";
import { DataSourcesApi } from "./nordicite/data-sources/data-sources.api";
import { RegisterApi } from "./nordicite/register/register.api";
import { EquipmentsApi } from "./nordicite/equipments/equipments.api";
import { EquipmentAttributesApi } from "./nordicite/equipments/equipment-attributes.api";
import { AttributeApi } from "./nordicite/attributes/attribute.api";
import { UnitConversionsApi } from "./nordicite/units/unit-conversions.api";
import { UnitsApi } from "./nordicite/units/units.api";
import { ProcessesApi } from "./nordicite/equipments/processes.api";
import { ResetPasswordApi } from "./nordicite/accounts/reset-password.api";
import { EquipmentCategoriesApi } from "./nordicite/equipments/equipment-categories.api";
import { UnitTypesApi } from "./nordicite/units/unit-types.api";
import { SetupPasswordApi } from "./nordicite/accounts/setup-password.api";
import { UniqueCodeApi } from "./nordicite/unique-code/unique-code.api";
import { MandatesApi } from "./nordicite/mandates/mandates.api";
import { JournalsApi } from "./nordicite/journals/journals.api";
import { HealthAndSafetyFormsApi } from "./nordicite/health-and-safety/apis/health-and-safety-forms.api";
import { HealthAndSafetyRecordsApi } from "./nordicite/health-and-safety/apis/health-and-safety-records.api";
import { GatewaysApi } from "./nordicite/gateways/gateways.api";
import { GatewayTypesApi } from "./nordicite/gateways/gateway-types.api";
import { ColorsApi } from "./nordicite/colors/colors.api";
import { RolesApi } from "./nordicite/accounts/roles.api";
import { IndicatorViewsApi } from "./nordicite/indicators/indicator-views.api";
import { IndicatorsApi } from "./nordicite/indicators/indicators.api";
import { OrganizationLaboratoriesApi } from "./nordicite/laboratories/organization-laboratories.api";
import { ReportsApi } from "./nordicite/reports/reports.api";
import { ReportResultsApi } from "./nordicite/reports/report-results.api";
import { AlertsApi } from "./nordicite/alerts/alerts.api";
import { UrgencyLevelsApi } from "./nordicite/urgency-levels/urgency-levels.api";
import { IncidentsApi } from "./nordicite/incidents/incidents.api";
import { EscalationLevelsApi } from "./nordicite/urgency-levels/escalation-levels.api";
import { LaboratoriesApi } from "./nordicite/laboratories/laboratories.api";
import { OrganizationProcessesApi } from "./nordicite/equipments/organization-processes.api";
import { ModulesApi } from "./nordicite/modules/modules.api";

@Injectable({
    providedIn: "root"
})
export class ApiService {
    constructor(
        public info: InfoApi,
        public account: AccountApi,
        public alerts: AlertsApi,
        public attributes: AttributeApi,
        public cache: CacheApi,
        public colors: ColorsApi,
        public countries: CountriesApi,
        public dataSources: DataSourcesApi,
        public equipments: EquipmentsApi,
        public equipmentAttributes: EquipmentAttributesApi,
        public equipmentCategories: EquipmentCategoriesApi,
        public escalationLevels: EscalationLevelsApi,
        public formulas: FormulasApi,
        public gateways: GatewaysApi,
        public gatewayTypes: GatewayTypesApi,
        public healthAndSafetyForms: HealthAndSafetyFormsApi,
        public healthAndSafetyRecords: HealthAndSafetyRecordsApi,
        public incidents: IncidentsApi,
        public indicatorView: IndicatorViewsApi,
        public indicators: IndicatorsApi,
        public journals: JournalsApi,
        public laboratories: LaboratoriesApi,
        public organization: OrganizationApi,
        public organizationLaboratories: OrganizationLaboratoriesApi,
        public mandate: MandatesApi,
        public modules: ModulesApi,
        public processes: ProcessesApi,
        public organizationProcesses: OrganizationProcessesApi,
        public register: RegisterApi,
        public reportResults: ReportResultsApi,
        public reports: ReportsApi,
        public resetPassword: ResetPasswordApi,
        public roles: RolesApi,
        public setupPassword: SetupPasswordApi,
        public uniqueCode: UniqueCodeApi,
        public unitConversion: UnitConversionsApi,
        public unitTypes: UnitTypesApi,
        public units: UnitsApi,
        public urgencyLevels: UrgencyLevelsApi
    ) {}
}
