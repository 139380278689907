import { DatabaseEntities } from "../../common/models/database-entities.model";
import { Indicators } from "./indicators.model";
import { plainToInstance, Type } from "class-transformer";
import { ObjectUtils } from "@recursyve/nice-ui-kit.v2";
import { IndicatorViewTypesEnum } from "../enums/indicator-view-types.enum";
import { isPropertyDefined } from "@recursyve/nice-ts-utils";

export class IndicatorViews extends DatabaseEntities {
    organizationId?: number;
    organizationProcessId?: number;
    type?: IndicatorViewTypesEnum;

    @Type(() => Indicators)
    indicators?: Indicators[];

    public clone(): IndicatorViews {
        return plainToInstance(IndicatorViews, ObjectUtils.deepCopy(this) as IndicatorViews);
    }

    public upsertIndicatorInPlace(
        upsertedIndicator: Partial<Indicators> & Pick<Indicators, "id">,
        isMoving?: boolean
    ): void {
        if (!this.indicators) {
            this.indicators = [];
        }

        for (let i = this.indicators.length - 1; i >= 0; i--) {
            const indicator = this.indicators[i];

            if (indicator.id === upsertedIndicator.id) {
                if (!!upsertedIndicator.parentIndicatorId) {
                    this.indicators.splice(i, 1);
                } else {
                    Object.assign(indicator, upsertedIndicator);
                    return;
                }
            }

            indicator.upsertIndicatorInPlace(upsertedIndicator);
        }

        // !isPropertyDefined makes the move indicator crash, without it, the update currentIndicator and creation crash
        if (!isPropertyDefined(upsertedIndicator, "parentIndicatorId") && !isMoving) {
            this.indicators.push(upsertedIndicator as Indicators);
        }
    }

    public deleteIndicator(deletedIndicator: Partial<Indicators> & Pick<Indicators, "id">): void {
        if (!this.indicators?.length) {
            return;
        }

        for (let index = 0; index < this.indicators.length; index++) {
            if (this.indicators[index].id === deletedIndicator.id) {
                this.indicators.splice(index, 1);
                return;
            }

            if (this.indicators[index].deleteIndicator(deletedIndicator)) {
                return;
            }
        }
    }
}
