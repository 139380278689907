import { Injectable } from "@angular/core";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Modules } from "./models/modules.model";
import { Observable } from "rxjs";

@Injectable()
export class ModulesApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("modules");
    }

    public getAll(): Observable<Modules[]> {
        return this.http.get<Modules[]>(this.url());
    }
}
