import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormulasApi } from "./nordicite/formulas/formulas.api";
import { InfoApi } from "./nordicite/info/info.api";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { AccountApi } from "./nordicite/accounts/account.api";
import { CountriesApi } from "./nordicite/coords/countries.api";
import { OrganizationApi } from "./nordicite/organizations/organization.api";
import { CacheApi } from "./nordicite/cache/cache.api";
import { DataSourcesApi } from "./nordicite/data-sources/data-sources.api";
import { RegisterApi } from "./nordicite/register/register.api";
import { LanguageInterceptor } from "./interceptors/language.interceptor";
import { EquipmentsApi } from "./nordicite/equipments/equipments.api";
import { EquipmentAttributesApi } from "./nordicite/equipments/equipment-attributes.api";
import { AttributeApi } from "./nordicite/attributes/attribute.api";
import { UnitConversionsApi } from "./nordicite/units/unit-conversions.api";
import { UnitsApi } from "./nordicite/units/units.api";
import { ProcessesApi } from "./nordicite/equipments/processes.api";
import { EquipmentCategoriesApi } from "./nordicite/equipments/equipment-categories.api";
import { UnitTypesApi } from "./nordicite/units/unit-types.api";
import { ResetPasswordApi } from "./nordicite/accounts/reset-password.api";
import { SetupPasswordApi } from "./nordicite/accounts/setup-password.api";
import { UniqueCodeApi } from "./nordicite/unique-code/unique-code.api";
import { MandatesApi } from "./nordicite/mandates/mandates.api";
import { JournalsApi } from "./nordicite/journals/journals.api";
import { HealthAndSafetyFormsApi } from "./nordicite/health-and-safety/apis/health-and-safety-forms.api";
import { HealthAndSafetyRecordsApi } from "./nordicite/health-and-safety/apis/health-and-safety-records.api";
import { GatewaysApi } from "./nordicite/gateways/gateways.api";
import { GatewayTypesApi } from "./nordicite/gateways/gateway-types.api";
import { ColorsApi } from "./nordicite/colors/colors.api";
import { RolesApi } from "./nordicite/accounts/roles.api";
import { NiceTransformResponseInterceptor } from "@recursyve/nice-ui-kit.v2";
import { IndicatorViewsApi } from "./nordicite/indicators/indicator-views.api";
import { IndicatorsApi } from "./nordicite/indicators/indicators.api";
import { OrganizationLaboratoriesApi } from "./nordicite/laboratories/organization-laboratories.api";
import { ReportsApi } from "./nordicite/reports/reports.api";
import { ReportResultsApi } from "./nordicite/reports/report-results.api";
import { AlertsApi } from "./nordicite/alerts/alerts.api";
import { UrgencyLevelsApi } from "./nordicite/urgency-levels/urgency-levels.api";
import { IncidentsApi } from "./nordicite/incidents/incidents.api";
import { EscalationLevelsApi } from "./nordicite/urgency-levels/escalation-levels.api";
import { LaboratoriesApi } from "./nordicite/laboratories/laboratories.api";
import { H2labLaboratoriesApi } from "./nordicite/laboratories/h2lab/h2lab-laboratories.api";
import { OrganizationProcessesApi } from "./nordicite/equipments/organization-processes.api";
import { ModulesApi } from "./nordicite/modules/modules.api";

@NgModule({
    providers: [
        InfoApi,
        AccountApi,
        AlertsApi,
        AttributeApi,
        CacheApi,
        ColorsApi,
        CountriesApi,
        DataSourcesApi,
        EquipmentsApi,
        EquipmentAttributesApi,
        EquipmentCategoriesApi,
        EscalationLevelsApi,
        FormulasApi,
        GatewaysApi,
        GatewayTypesApi,
        H2labLaboratoriesApi,
        HealthAndSafetyFormsApi,
        HealthAndSafetyRecordsApi,
        IncidentsApi,
        IndicatorViewsApi,
        IndicatorsApi,
        JournalsApi,
        LaboratoriesApi,
        OrganizationApi,
        OrganizationLaboratoriesApi,
        MandatesApi,
        ModulesApi,
        ProcessesApi,
        OrganizationProcessesApi,
        RegisterApi,
        ReportResultsApi,
        ReportsApi,
        ResetPasswordApi,
        RolesApi,
        SetupPasswordApi,
        UniqueCodeApi,
        UnitConversionsApi,
        UnitTypesApi,
        UnitsApi,
        UrgencyLevelsApi,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NiceTransformResponseInterceptor, multi: true }
    ]
})
export class ApiModule {}
