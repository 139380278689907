import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { InfoModel } from "../api/nordicite/info/models/info.model";
import { Accounts } from "../api/nordicite/accounts/models/accounts.model";
import { ToolbarItem } from "../features/dashboard/components/toolbar/toolbar.item";
import { Organizations } from "../api/nordicite/organizations/models/organizations.model";
import { OrganizationLaboratoriesConfigurationStatus } from "../api/nordicite/laboratories/types/organization-laboratories-configuration-status.type";
import { ReportsSignatureStatus } from "../api/nordicite/reports/interfaces/reports-signature-status.interface";

export interface AppState {
    serverState?: InfoModel | null;
    currentAccount?: Accounts | null;
    currentToolbarItem?: ToolbarItem | null;
    currentOrganization?: Organizations | null;
    currentOrganizationLaboratoriesConfigurationStatus?: OrganizationLaboratoriesConfigurationStatus | null;
    userHasMultipleOrganizations: boolean;
    shouldShowNav: boolean;
    currentOrganizationReportsSignatureStatus: ReportsSignatureStatus[];
}

const createInitialState: AppState = {
    serverState: null,
    currentAccount: null,
    currentToolbarItem: ToolbarItem.Organizations,
    currentOrganization: null,
    currentOrganizationLaboratoriesConfigurationStatus: null,
    userHasMultipleOrganizations: false,
    shouldShowNav: false,
    currentOrganizationReportsSignatureStatus: []
};

@Injectable()
@StoreConfig({ name: "app", resettable: true })
export class AppStore extends Store<AppState> {
    constructor() {
        super(createInitialState);
    }

    public reset(): void {
        const { serverState } = this.getValue();
        super.reset();
        this.update({ serverState });
    }
}
