import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { Observable } from "rxjs";
import { FindByNameApi } from "../settings/interfaces/find-by-name-api.interface";
import { UpdateActiveStatusDto } from "../organizations/dto/update-active-status.dto";
import { UpdateProcessRankDto } from "./dto/update-process-rank.dto";
import { UpsertProcessDto } from "./dto/upsert-process.dto";
import { OrganizationProcesses } from "./models/organization-processes.model";

@Injectable()
export class OrganizationProcessesApi extends NordiciteBaseFilterApi<OrganizationProcesses> implements FindByNameApi {
    constructor(http: HttpClient) {
        super("organization-process", http);
    }

    public update(id: number, dto: UpsertProcessDto): Observable<OrganizationProcesses> {
        return this.http.put<OrganizationProcesses>(this.url(`${id}`), dto, {
            params: { skipTranslation: true }
        });
    }

    public updateActiveStatus(id: number, dto: UpdateActiveStatusDto): Observable<OrganizationProcesses> {
        return this.http.put<OrganizationProcesses>(this.url(`${id}/status`), dto, {
            params: { skipTranslation: true }
        });
    }

    public updateProcessRank(id: number, dto: UpdateProcessRankDto): Observable<OrganizationProcesses> {
        return this.http.put<OrganizationProcesses>(this.url(`${id}/rank`), dto, { params: { skipTranslation: true } });
    }
}
