import { Injectable } from "@angular/core";
import { NiceNavigationService } from "@recursyve/nice-ui-kit.v2";
import { combineLatest } from "rxjs";
import { AppQuery } from "../store/app.query";
import { Accounts } from "../api/nordicite/accounts/models/accounts.model";
import { Organizations } from "../api/nordicite/organizations/models/organizations.model";
import { CustomNavigationItem, navigationItems } from "./navigation-items";
import { RolesEnum } from "../api/nordicite/accounts/enums/roles.enum";
import { OrganizationModuleNavigationItemHintComponent } from "../features/dashboard/organizations/components/navigation-module-hint/organization-module-navigation-item-hint.component";
import { OrganizationModuleNavigationItemHintResolver } from "../features/dashboard/organizations/components/navigation-module-hint/organization-module-navigation-item-hint.resolver";

@Injectable({
    providedIn: "root"
})
export class NavigationService {
    constructor(private niceNavigationService: NiceNavigationService, private appQuery: AppQuery) {}

    private static getOrganization(currentOrganization: Organizations, user: Accounts): Organizations {
        if (user.permissions?.includes("nordicite:read:organizations-all")) {
            return currentOrganization ?? null;
        }
        return currentOrganization ?? user.organization;
    }

    public async init(): Promise<void> {
        combineLatest([
            this.appQuery.selectCurrentAccount(),
            this.appQuery.selectCurrentOrganization(),
            this.appQuery.selectCurrentToolbarItem()
        ]).subscribe(([user, currentOrganization]) => {
            if (!user || !currentOrganization) {
                return;
            }

            this.setCurrentNavigation(user, NavigationService.getOrganization(currentOrganization, user));
        });
    }

    public async setCurrentNavigation(account: Accounts, organization: Organizations | null): Promise<void> {
        const navigation: CustomNavigationItem[] = [];
        this.niceNavigationService.deleteNavigation("default");

        const items = navigationItems(account, organization);

        for (const item of items) {
            if (item.permission && !account.permissions?.includes(item.permission)) {
                continue;
            }

            const updatedItem = this.setNavigationItemModule(item, account);
            if (!updatedItem) {
                continue;
            }

            const { children, ...values } = updatedItem;

            (values as CustomNavigationItem).children = await this.getNavigationChild(account, children ?? []);
            navigation.push(values);
        }

        this.niceNavigationService.storeNavigation("default", navigation);
        this.niceNavigationService.setCurrentNavigation("default");
    }

    private setNavigationItemModule(item: CustomNavigationItem, account: Accounts): CustomNavigationItem | null {
        if (!item.moduleSlug) {
            return item;
        }

        const moduleEnabled = this.appQuery.hasModule(item.moduleSlug);
        if (!moduleEnabled) {
            item.classes ??= {};
            item.classes.wrapper = "module-disabled";

            switch (account.role?.role) {
                case RolesEnum.OrganizationManager:
                    item.disabled = true;
                    break;
                case RolesEnum.SuperAdmin:
                    item.classes.wrapper += " module-disabled-admin";
                    break;
                default:
                    return null;
            }

            item.hint = {
                resolver: OrganizationModuleNavigationItemHintResolver,
                component: OrganizationModuleNavigationItemHintComponent
            };
        }
        return item;
    }

    public async getNavigationChild(account: Accounts, items: CustomNavigationItem[]): Promise<CustomNavigationItem[]> {
        if (!items) {
            return [];
        }

        const res: CustomNavigationItem[] = [];
        for (const item of items) {
            if ((item.permission && !account.permissions?.includes(item.permission)) ?? false) {
                continue;
            }

            const { children, ...values } = item;

            (values as CustomNavigationItem).children = await this.getNavigationChild(account, children ?? []);
            res.push(values);
        }
        return res;
    }
}
