import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Organizations } from "./models/organizations.model";
import { OrganizationDataSourcesApi } from "./organization-data-sources.api";
import { map } from "rxjs/operators";
import { UpsertOrganizationDto } from "./dto/upsert-organization.dto";
import { FormDataUtils } from "@recursyve/nice-ui-kit.v2";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { UpdateActiveStatusDto } from "./dto/update-active-status.dto";
import { OrganizationConnection } from "./models/organization-connection.model";
import { OrganizationAccountsApi } from "./organization-accounts.api";
import { OrganizationMandatesApi } from "./organization-mandates.api";
import { OrganizationJournalsApi } from "./organization-journals.api";
import { OrganizationFindOptionsDto } from "./dto/organization-find-options.dto";
import { OrganizationHealthAndSafetyApi } from "./organization-health-and-safety.api";
import { BreadcrumbItems } from "@recursyve/ngx-panko";
import { OrganizationIndicatorViewsApi } from "./organization-indicator-views.api";
import { OrganizationReportsApi } from "./organization-reports.api";
import { OrganizationAlertsApi } from "./organization-alerts.api";
import { UpdateOrganizationGuardSmsDto } from "../settings/dto/update-organization-guard-sms.dto";
import { OrganizationEscalationLevelsApi } from "./organization-escalation-levels.api";
import { OrganizationOrganizationProcessesApi } from "./organization-organization-processes.api";
import { OrganizationModules } from "../modules/models/organization-modules.model";

@Injectable()
export class OrganizationApi extends NordiciteBaseFilterApi<Organizations> {
    constructor(http: HttpClient) {
        super("organization", http);
    }

    public accounts(organizationId: number): OrganizationAccountsApi {
        return new OrganizationAccountsApi(organizationId, this.http);
    }

    public alerts(organizationId: number): OrganizationAlertsApi {
        return new OrganizationAlertsApi(organizationId, this.http);
    }

    public dataSources(organizationId: number): OrganizationDataSourcesApi {
        return new OrganizationDataSourcesApi(organizationId, this.http);
    }

    public escalationLevels(organizationId: number): OrganizationEscalationLevelsApi {
        return new OrganizationEscalationLevelsApi(organizationId, this.http);
    }

    public healthAndSafety(organizationId: number): OrganizationHealthAndSafetyApi {
        return new OrganizationHealthAndSafetyApi(organizationId, this.http);
    }

    public indicatorViews(organizationId: number): OrganizationIndicatorViewsApi {
        return new OrganizationIndicatorViewsApi(organizationId, this.http);
    }

    public journals(organizationId: number): OrganizationJournalsApi {
        return new OrganizationJournalsApi(organizationId, this.http);
    }

    public mandates(organizationId: number): OrganizationMandatesApi {
        return new OrganizationMandatesApi(organizationId, this.http);
    }

    public processes(organizationId: number): OrganizationOrganizationProcessesApi {
        return new OrganizationOrganizationProcessesApi(organizationId, this.http);
    }

    public reports(organizationId: number): OrganizationReportsApi {
        return new OrganizationReportsApi(organizationId, this.http);
    }

    public create(dto: UpsertOrganizationDto, image: File): Observable<Organizations> {
        const formData = new FormData();
        FormDataUtils.appendFormDataWithDto(formData, dto);
        formData.append("image", image);

        return this.http.post<Organizations>(this.url(), formData);
    }

    public getConnectionFromSlug(slug: string): Observable<OrganizationConnection> {
        return this.http.get<OrganizationConnection>(this.url(`${slug}/connection`));
    }

    public getFromSlug(slug: string): Observable<Organizations> {
        return this.http.get<Organizations>(this.url(`slug/${slug}`));
    }

    public getBreadcrumbById(id: number): Observable<BreadcrumbItems[]> {
        return this.http.get<BreadcrumbItems[]>(this.url(`${id}/breadcrumb`));
    }

    public getById(id: number): Observable<Organizations> {
        return this.http.get<Organizations>(this.url(`${id}`));
    }

    public search(dto: OrganizationFindOptionsDto): Observable<Organizations[]> {
        return this.http.get<Organizations[]>(this.url(), {
            params: { ...dto }
        });
    }

    public isSlugAlreadyUsed(slug: string): Observable<boolean> {
        return this.http.get<{ used: boolean }>(this.url(`${slug}/already-used`)).pipe(map(d => d.used));
    }

    public getOrganizationModules(id: number): Observable<OrganizationModules[]> {
        return this.http.get<OrganizationModules[]>(this.url(`${id}/modules`));
    }

    public update(id: number, dto: UpsertOrganizationDto, image?: File): Observable<Organizations> {
        const formData = new FormData();
        FormDataUtils.appendFormDataWithDto(formData, dto);

        if (image) {
            formData.append("image", image);
        }

        return this.http.put<Organizations>(this.url(`${id}`), formData);
    }

    public updateActiveStatus(id: number, dto: UpdateActiveStatusDto): Observable<Organizations> {
        return this.http.put<Organizations>(this.url(`${id}/status`), dto);
    }

    public updateGuardSms(id: number, dto: UpdateOrganizationGuardSmsDto): Observable<Organizations> {
        return this.http.put<Organizations>(this.url(`${id}/guard-sms`), dto);
    }
}
