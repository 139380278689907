import { HttpClient } from "@angular/common/http";
import { ProcessesFindOptionsDto } from "../equipments/dto/processes-find-options.dto";
import { Observable } from "rxjs";
import { OrganizationProcesses } from "../equipments/models/organization-processes.model";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { UpsertProcessDto } from "../equipments/dto/upsert-process.dto";

export class OrganizationOrganizationProcessesApi extends NordiciteBaseFilterApi<OrganizationProcesses> {
    constructor(id: number, protected http: HttpClient) {
        super(`organization/${id}/process`, http);
    }

    public create(dto: UpsertProcessDto): Observable<OrganizationProcesses> {
        return this.http.post<OrganizationProcesses>(this.url(), dto);
    }

    public getAll(dto?: ProcessesFindOptionsDto): Observable<OrganizationProcesses[]> {
        return this.http.get<OrganizationProcesses[]>(this.url(), {
            params: { ...dto }
        });
    }
}
