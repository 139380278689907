<nice-layout>
    <!-- Navigation -->
    <ng-container *ngIf="{ currentAccount: currentAccount$ | async } as ctx">
        <nordicite-navbar [opened]="shouldShowNav$ | async"></nordicite-navbar>

        <!-- Wrapper -->
        <div class="flex flex-col flex-auto w-full min-w-0 h-screen">
            <!-- Header -->
            <div
                *ngIf="ctx.currentAccount.role.role === RolesEnum.SuperAdmin"
                class="horizontal-nav relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 print:hidden"
            >
                <!-- Components -->
                <div class="flex items-center pl-2 ml-auto space-x-2 w-full">
                    <nordicite-toolbar
                        class="w-full"
                        [navBarIsCollapsed]="(shouldShowNav$ | async) === false"
                    ></nordicite-toolbar>
                </div>
            </div>

            <!-- Content -->
            <div class="page-content flex flex-col flex-auto inset-0 overflow-y-auto h-full">
                <router-outlet></router-outlet>
            </div>
        </div>
    </ng-container>
</nice-layout>
